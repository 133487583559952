import { styled } from '../../modules';
import { Typography } from '../../components';

export const CollectImageTitle = styled(Typography)`
  margin-top: '2rem';
  margin-bottom: '1rem';
  padding: '0 0.3rem';
  font-size: '13px';
`;

export const DataDisplayTitle = styled(Typography)`
  margin-top: '2rem';
  margin-bottom: '1rem';
  padding: '0 0.3rem';
  font-size: '25px';
`;

export const DataDisplayDescription = styled(Typography)`
  margin-top: '2rem';
  margin-bottom: '1rem';
  padding: '0 0.3rem';
  font-size: '13px';
`;

export const DocumentText = styled(Typography)`
  font-weight: bold;
  font-size: 13px;
  text-align: left;
`;

export const DocumentImg = styled.img`
  width: 105px;
  max-height: 105px;
  object-fit: contain;
  margin: auto 0;
`;

export const DocumentIcon = styled.img`
  width: 25px;
  max-height: 25px;
  object-fit: contain;
  margin: auto 0;
  margin-right: 10px;
`;

export const DocumentImgCnh = styled(DocumentImg)`
  max-height: 105px;
  margin: auto 0;
`;

export const PersonalDocumentConfirmationContainer = styled.div`
 margin-top: 16px;
 width: 100%;
 border-radius: 8px;
 box-shadow: #646464ab 0 2px 8px;
 padding: 8px;
`;