import React, { useEffect } from "react"
import { Wallpaper } from '../../components/Wallpaper'
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline"
import { Footer } from '../../components/Footer'
import { useCookies } from 'react-cookie'


export const SignedPageWatcher = (props) => {
    const { clientThemeSettings, watcherHash } = props
    const [cookies, setCookie] = useCookies([`qisign_${watcherHash}`])


    useEffect(() => {
        setCookie(`qisign_${watcherHash}`, cookies, { path: `/w/${watcherHash}`, maxAge: 0 })
    }, [setCookie, cookies, watcherHash])

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]}>
            <div className="base-container base-container-width base-container-padding" style={{ display: "flex", backgroundColor: "rgb(245, 245, 242)", minHeight: "40%", alignItems: "center", textAlign: "center" }}>
                <div style={{ margin: "auto" }} className="gray">
                    <CheckCircleIcon className="green-color" style={{ width: "50px", height: "50px", margin: "20px" }} />
                    <h1 className="title-level2" style={{ margin: "auto" }}>Documentos assinados com sucesso</h1>
                </div>
                <Footer showLink={true} />
            </div>
        </Wallpaper>
    )
}
