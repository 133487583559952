import { useEffect, useState } from 'react'

import axios from 'axios'

import { Box } from '@mui/material'
import { Logo } from '../../components';
import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import { Footer } from "../../components/Footer"
import { Wallpaper } from '../../components/Wallpaper'
import { DataDisplayDescription, DataDisplayTitle} from '../PersonalDocument/styles';
import { Button } from '../../components';

export const FraudWarning = (props) => {

    const { clientThemeSettings, signData, setSignerStep, setSignData } = props
    const initialError = {"has_error": false, "status": 0}

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)

    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    const handleClick = () => {

        if (hasMadeRequest) {
            return  
        }

        const payload = {
            "step": "fraud_warning",
            "event_date": new Date().toISOString()
        }
    
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }
    
        setLoading(true)
        setHasMadeRequest(true)

        axios.patch('/signer', payload, config)
        .then(response => {
            setSignerStep(response.data.next_step)
            setSignData({ ...signData, ...response.data })
        })
        .catch((error) => {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": error.response.status
                })
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                })
            }

            setHasMadeRequest(false)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]} style={{ padding: '10px' }}>
            <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />
                <Box>
                    {(
                        <div>
                            <DataDisplayTitle className="title-level1">
                                Evite golpes!
                            </DataDisplayTitle>

                            <DataDisplayDescription className="description-level4" style={{marginBottom: "5px"}}>
                                <DataDisplayDescription>
                                    O cancelamento de contrato <b>{signData.company_name}</b> é realizado <b>somente</b> pela nossa 
                                    <b> Central de Atendimento</b>, mediante crédito em conta de titularidade <b>{signData.company_name}</b>.
                                </DataDisplayDescription>
                                <DataDisplayDescription>
                                    O processo de portabilidade é sempre realizado entre os bancos. <b>Nunca</b> pague boletos ou realize 
                                    transferências (<b>PIX/TED</b>) para contas de terceiros.
                                </DataDisplayDescription>
                                <DataDisplayDescription>
                                    Não aceite propostas de investimentos com o valor do empréstimo, como <b>"aluguel de margem"</b>.
                                    Trata-se de um golpe.
                                </DataDisplayDescription>
                                <DataDisplayDescription>
                                    <b>{signData.company_name} não solicita</b> o pagamento de qualquer quantia para a realização do seu contrato.
                                </DataDisplayDescription>
                                <DataDisplayDescription style={{marginBottom: "25px"}}>
                                    Caso ocorra qualquer uma das situações acima, entre em contato com a nossa <b>Central de Atendimento</b>.
                                </DataDisplayDescription >
                            </DataDisplayDescription>

                            <Button
                                onClick={handleClick}
                                type='primary'
                                clientThemeSettings={clientThemeSettings}
                            >
                                ESTOU CIENTE
                            </Button>

                        </div>
                    )}
                </Box>
                <Footer />
            </div>
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />
            }
        </Wallpaper>
    )
}