import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { MainContainer } from '../MainContainer'
import { WatcherMainContainer } from '../WatcherMainContainer'
import { SignerBatchMainContainer } from "../SignerBatchMainContainer"
import { NotFoundPage } from '../NotFoundPage'
import { defaultThemeSettings } from '../../utils/utils'

export const MainRouter = (props) => {

    const router = createBrowserRouter([
        {
            path: "/s/:signerHash",
            element: <MainContainer />
        },
        {
            path: "/sb/:signerBatchHash",
            element: <SignerBatchMainContainer/>
        },
        {
            path: "/w/:watcherHash",
            element: <WatcherMainContainer/>
        },
        {
            path: "*",
            element: <NotFoundPage clientThemeSettings={defaultThemeSettings} />
        }
    ])

    return (
        <div>
            <CssBaseline />
            <main style={{ height: "100vh" }}>
                <RouterProvider router={router} />
            </main>
        </div>
    )
}
