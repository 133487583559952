import { useEffect, useState } from 'react'

import axios from 'axios'

import { Box } from '@mui/material'
import { Logo } from '../../components';
import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import { Footer } from "../../components/Footer"
import { Wallpaper } from '../../components/Wallpaper'
import { DataDisplayTitle, DataDisplayDescription } from '../PersonalDocument/styles';
import { Button } from '../../components';

export const TermOfAuthorization = (props) => {

    const { clientThemeSettings, signData, setSignerStep, setSignData } = props
    const initialError = {"has_error": false, "status": 0}

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)

    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }
    const handleClick = () => {

        if (hasMadeRequest) {
            return  
        }

        const payload = {
            "step": "term_of_authorization",
            "event_date": new Date().toISOString()
        }
    
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }
    
        setLoading(true)
        setHasMadeRequest(true)

        axios.patch('/signer', payload, config)
        .then(response => {
            setSignerStep(response.data.next_step)
            setSignData({ ...signData, ...response.data })
        })
        .catch((error) => {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": error.response.status
                })
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                })
            }

            setHasMadeRequest(false)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]} style={{ padding: '10px' }}>
            <Box className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)", marginBottom: "110px" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />
                <Box style={{width: '95%'}}>
                    {(
                        <Box>
                            <Box style={{textAlign: "center"}}>
                            <DataDisplayTitle className="title-level4" >
                            Termo de Autorização de Consulta de Dados
                            </DataDisplayTitle>
                            </Box>

                            <DataDisplayDescription className="description-level2">
                            Por meio deste Termo de Autorização de
                            Consulta de Dados, o cliente qualificado na proposta de crédito <b>("Você")</b> autoriza o <b>INSS/DATAPREV</b> a disponibilizar as informações abaixo para fins de contratação de empréstimo consignado junto à <b>QI SOCIEDADE DE CREDITO DIRETO S.A.</b>.
                            </DataDisplayDescription>
                            <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', width: '100%' }}>
                                <Box
                                    style={{
                                        width: '100%',
                                        alignItems: 'center',
                                        padding: '10px 15px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        backgroundColor: '#f9f9f9',
                                        outline: 'none',
                                        WebkitTapHighlightColor: 'transparent',
                                        margin: '0 auto',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}
                                    type='primary'>
                                    <DataDisplayDescription><b>Dados de Identificação:</b></DataDisplayDescription>
                                        <ul>
                                            <li>CPF</li>
                                            <li>Data de nascimento</li>
                                            <li>Nome completo</li>
                                        </ul>
                                </Box>
                                <Box
                                        style={{
                                            width: '100%',
                                            alignItems: 'center',
                                            padding: '10px 15px',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            backgroundColor: '#f9f9f9',
                                            outline: 'none',
                                            WebkitTapHighlightColor: 'transparent',
                                            margin: '0 auto',
                                            marginBottom: '10px'
                                        }}
                                        type='primary'>
                                    <DataDisplayDescription><b>Dados do Benefício:</b></DataDisplayDescription>
                                    <ul>
                                        <li>Número do benefício</li>
                                        <li>Situação do benefício</li>
                                        <li>Indicação de que o benefício foi concedido por liminar</li>
                                        <li>Data de Cessação do Benefício - DCB (se houver)</li>
                                        <li>Possui representante legal</li>
                                        <li>Possui procurador</li>
                                        <li>Possui entidade de representação</li>
                                        <li>Pensão alimentícia</li>
                                        <li>Bloqueado para empréstimo</li>
                                        <li>Data da última perícia médica</li>
                                        <li>Data do despacho do benefício - DDB</li>
                                        <li>Possui entidade</li>
                                        <li>Valor total comprometido</li>
                                        <li>Valor máximo de comprometimento permitido</li>
                                        <li>Dados do Pagamento do Benefício:</li>
                                        <li>UF onde o beneficiário recebe os proventos</li>
                                        <li>Tipo de crédito (cartão ou conta-corrente)</li>
                                        <li>Agência pagadora</li>
                                        <li>Indicação da instituição financeira que paga o benefício</li>
                                        <li>Conta-corrente onde o benefício é pago</li>
                                        <li>Margem consignável disponível</li>
                                        <li>Margem consignável disponível para Cartão</li>
                                        <li>Valor limite para cartão</li>
                                        <li>Quantidade de empréstimos ativos/suspensos</li>
                                    </ul>
                                </Box>
                            </Box>
                            <DataDisplayDescription className="description-level2">
                            Este Termo autoriza a <b>QI SOCIEDADE DE CREDITO DIRETO S.A.</b> consultar suas informações durante o período de até 30 (trinta) dias. Este pedido poderá ser efetuado pela <b>QI SOCIEDADE DE CREDITO DIRETO S.A.</b> até 45 (quarenta e cinco) dias após a assinatura deste instrumento.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            Você reconhece a validade da assinatura eletrônica que utilizou para aceitar as condições deste Termo (coleta de seus dados biométricos e/ou aceite eletrônico, a depender do sistema ou plataforma utilizados), nos termos do <b>parágrafo 2º do artigo 10 da Medida Provisória nº 2.200/01</b>.
                            </DataDisplayDescription>      

                        </Box>
                    )}
                </Box>
            </Box>
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />
            }
                <Box
                        style={{
                            position: "fixed",
                            bottom: 0,
                            width: "100%",
                            alignItems: "center",
                            textAlign: "center",
                            backgroundColor: "#f9f9f9",
                            padding: "12px 10px",
                            borderTop: "1px solid #ccc"
                        }}
                    >
                        <Button
                            onClick={handleClick}
                            type="primary"
                            clientThemeSettings={clientThemeSettings}
                        >
                            ESTOU CIENTE
                        </Button>
                        <Footer />
                </Box>
        </Wallpaper>
    )
}