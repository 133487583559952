import React, { useEffect } from "react";
import { Wallpaper } from '../../components/Wallpaper';
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Footer } from '../../components/Footer';
import { useCookies } from 'react-cookie';

export const WatcherPage = (props) => {
    const { clientThemeSettings, signerHash, signerList } = props;
    const [cookies, setCookie] = useCookies([`qisign_${signerHash}`]);

    const signedStatuses = [
        "signed", "sending_sign_receipt", "receipt_sent", "analyzing", "analyzed"
    ];

    useEffect(() => {
        setCookie(`qisign_${signerHash}`, cookies, { path: `/w/${signerHash}`, maxAge: 0 });
    }, [setCookie, cookies, signerHash]);

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]}>
            <div className="base-container base-container-width base-container-padding" style={{ display: "flex", flexDirection: "column", backgroundColor: "rgb(245, 245, 242)", minHeight: "40%", alignItems: "center", textAlign: "center" }}>

                <div style={{ width: "100%", marginTop: "20px" }}>
                    <h2 className="title-level3" style={{ marginBottom: "20px" }}>Status dos Assinantes</h2>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                        {signerList.map((signer, index) => {
                            const isSigned = signedStatuses.includes(signer.signer_status);
                            const statusText = isSigned ? "Assinado" : "Pendente";
                            const statusColor = isSigned ? "green" : "#FF6A13";
                            const Icon = isSigned ? CheckCircleIcon : AccessTimeIcon;

                            return (
                                <li
                                    key={index}
                                    style={{
                                        margin: "10px 0",
                                        padding: "10px",
                                        backgroundColor: "#fff",
                                        border: "1px solid #ddd",
                                        borderRadius: "5px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        color: statusColor
                                    }}
                                >
                                    <div style={{ fontWeight: "bold" }}>
                                        {signer.signer_name}
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ marginRight: "10px" }}>
                                            {statusText}
                                        </span>
                                        <Icon
                                            className={isSigned ? "green-color" : "orange-color"}
                                            style={{
                                                width: isSigned ? "20px" : "20px",
                                                height: isSigned ? "24px" : "20px",
                                                color: isSigned ? "#2E7D32" : "#FF6A13",
                                                fontWeight: isSigned ? "bold" : "normal",
                                            }}
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <Footer showLink={true} />
            </div>
        </Wallpaper>
    );
};
