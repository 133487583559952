import { useEffect, useState } from 'react'

import axios from 'axios'

import { Box } from '@mui/material'
import { Logo } from '../../components';
import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import { Footer } from "../../components/Footer"
import { Wallpaper } from '../../components/Wallpaper'
import { DataDisplayDescription } from '../PersonalDocument/styles';
import { Button } from '../../components';
import { Checkbox } from "@mui/material";


export const ResumeOperation = (props) => {

    const { clientThemeSettings, signData, setSignerStep, setSignData } = props
    const initialError = {"has_error": false, "status": 0}

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked)
    };

    function formatDate(inputDate) {
        const date = new Date(inputDate)
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()
      
        return `${day}/${month}/${year}`
      }

    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    const handleClick = () => {

        if (hasMadeRequest) {
            return  
        }

        const payload = {
            "step": "resume_operation",
            "event_date": new Date().toISOString()
        }
    
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }
    
        setLoading(true)
        setHasMadeRequest(true)

        axios.patch('/signer', payload, config)
        .then(response => {
            setSignerStep(response.data.next_step)
            setSignData({ ...signData, ...response.data })
        })
        .catch((error) => {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": error.response.status
                })
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                })
            }

            setHasMadeRequest(false)
        })
        .finally(() => {
            setLoading(false)
        })
    }


    const formatCurrency = (value) => {
        const valueInReais = value / 100;
    
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(valueInReais);
      };

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]} style={{ padding: '10px' }}>
            <Box className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)", marginBottom: "180px" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />
                <Box style={{width: '95%'}}>
                    {(
                        <Box>
                            <DataDisplayDescription style={{textAlign: "center"}}><b>Resumo do contrato</b></DataDisplayDescription>
                            <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', width: '100%' }}>
                            <Box className="box-display-container">

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                    Valor a receber:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                    <b>{formatCurrency(signData.credit_operation_data.disbursed_amount)}</b>
                                    </DataDisplayDescription>
                                </Box>

                                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                    Parcelas:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                    <b>{signData.credit_operation_data.number_of_installments}x de R$ NAO TEMOS O VALOR</b>
                                    </DataDisplayDescription>
                                </Box> */}


                            </Box>
                            <Box className="box-display-container">

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                        Valor total financiado:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                        <b>{formatCurrency(signData.credit_operation_data.issue_amount)}</b>
                                    </DataDisplayDescription>
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                        CET (a.m):
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                        <b>{signData.credit_operation_data.monthly_cet} % ao mês</b>
                                    </DataDisplayDescription>
                                </Box>

                            </Box>
                            <Box className="box-display-container">

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                    Data da primeira parcela:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                        <b>{formatDate(signData.credit_operation_data.first_due_date)}</b>
                                    </DataDisplayDescription>
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                        Taxa de juros mensal:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                        <b>{signData.credit_operation_data.monthly_interest_rate} %</b>
                                    </DataDisplayDescription>
                                </Box>

                                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                    Taxa de juros anual:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                        <b>NAO TEMOS O VALOR AO ANO %</b>
                                    </DataDisplayDescription>
                                </Box> */}

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                    IOF:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                    <b>{formatCurrency(signData.credit_operation_data.iof)}</b>
                                    </DataDisplayDescription>
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                    Seguro:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                    <b>SIM</b>
                                    </DataDisplayDescription>
                                </Box>

                            </Box>
                            <Box className="box-display-container">

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                    Beneficiário:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                    <b>{signData.credit_operation_data.borrower.name}</b>
                                    </DataDisplayDescription>
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <DataDisplayDescription style={{ flex: 1 }}>
                                    CPF:
                                    </DataDisplayDescription>
                                    <DataDisplayDescription style={{ textAlign: 'right' }}>
                                    <b>{signData.credit_operation_data.borrower.document_number}</b>
                                    </DataDisplayDescription>
                                </Box>

                            </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />
            }
                <Box
                        style={{
                            position: "fixed",
                            bottom: 0,
                            width: "100%",
                            alignItems: "center",
                            textAlign: "center",
                            backgroundColor: "#f9f9f9",
                            padding: "10px 15px",
                            borderTop: "1px solid #ccc"
                        }}
                    >
                        {/* <FormControlLabel
                            control={
                            <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                            }
                            label="Declaro que li e aceito que o valor do seguro será incluído no contrato."
                        /> */}
                        <Box style={{ display: "flex", alignItems: "left", justifyContent: "left", textAlign: "left", padding: "10px"}}>
                                <DataDisplayDescription className="description-level4" style={{ marginRight: "5px", marginLeft: "10px" }}>
                                <b>Declaro que li e estou de acordo com as condições do contrato acima.</b>
                                </DataDisplayDescription>
                                <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                color="primary"
                                style={{marginRight: "10px"}}
                                />
                        </Box>
                        <Button
                            onClick={handleClick}
                            type="primary"
                            disabled={!isChecked}
                            clientThemeSettings={clientThemeSettings}

                        >
                            Aceitar termos do contrato
                        </Button>
                        <Footer />
                </Box>
        </Wallpaper>
    )
}